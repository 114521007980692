import React, { useContext, useEffect, useState } from 'react';
import { LocalUser, RemoteUser, useIsConnected, useJoin, useLocalMicrophoneTrack, usePublish, useRemoteUsers } from 'agora-rtc-react';
import { FiMicOff, FiMic } from "react-icons/fi";
import { IoCall } from "react-icons/io5";
import { MyContext } from '../Context/MyProvider';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../Users_Chats/Firebase';

export const Basics = ({ Id, name, img, channelname }) => {

  const { setIsVoiceCalling, setAtendCall, setCallstatus } = useContext(MyContext);

  const [calling, setCalling] = useState(false);
  const isConnected = useIsConnected();
  const [appId, setAppId] = useState("a1d71b610a904e26919704593fd53313");
  const [channel, setChannel] = useState(channelname);
  const [token] = useState("");
  

  useJoin({ appid: appId, channel: channel, token: token ? token : null }, calling);

  const [micOn, setMic] = useState(true);
  const { localMicrophoneTrack } = useLocalMicrophoneTrack(micOn);
  usePublish([localMicrophoneTrack]);

  const remoteUsers = useRemoteUsers();

  useEffect(() => {
    if (remoteUsers.length === 0 && calling) {
      setCalling(false);
      setIsVoiceCalling(false);
      setCallstatus(false);
      setAtendCall(false);
    }
  }, [remoteUsers])

  useEffect(() => {
    console.log(" //////////////////////////// ", channel);
  },[channel])

  useEffect(() => {
    if (!Id && !calling) return;
    setCalling(true);
    setChannel(channelname);
  }, [Id, name, channelname])

  const LeaveCall = async () => {

    if (channel) {
      const userRef1 = doc(db, "chat_rooms", channel);

      const isVcDocRef1 = doc(userRef1, "isVcAvailable", channel);

      await updateDoc(isVcDocRef1, { Audio: false })
        .then(() => {
          console.log(`Document in ${channel} updated successfully.`);
        })
        .catch(() => {
          console.log(`Document ${channel} does not exist, skipping update.`);
        })
    }
    setIsVoiceCalling(false);
    setCalling(false);
    setCallstatus(false);
    setAtendCall(false);
  }

  return (
    <>
      <div className="flex items-end Callimg justify-center" style={{
        backgroundImage: `url(${img})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "500px",
        width: "100%",
      }}>
        <div className="user-list">
          <div className="user">
            <LocalUser
              audioTrack={localMicrophoneTrack}
              micOn={micOn}
              cover="https://www.agora.io/en/wp-content/uploads/2022/10/3d-spatial-audio-icon.svg"
            >
              <samp className="user-name">You</samp>
            </LocalUser>
          </div>
          {remoteUsers.map((user) => (
            <div className="user" key={user.uid}>
              <RemoteUser cover="https://www.agora.io/en/wp-content/uploads/2022/10/3d-spatial-audio-icon.svg" user={user}>
                <samp className="user-name">{user.uid}</samp>
              </RemoteUser>
            </div>
          ))}
          <div className="z-[999] relative">
            <div className="text-white text-center">
              <h6 className="text-[35px] m-0">{name}</h6>
              {!remoteUsers.length > 0 ? <h6>Ringing...</h6> : "Conected"}
            </div>
            {isConnected && (
              <div className="control flex items-center justify-center">
                <div className="left-control">
                  <button className="btn" onClick={() => setMic((prev) => !prev)}>
                    {micOn ? (
                      <FiMic className="i-microphone bg-slate-600 text-white rounded-full p-[12px] w-[45px] h-[45px]" />
                    ) : (
                      <FiMicOff className="i-microphone off bg-slate-600 text-white rounded-full p-[12px] w-[45px] h-[45px]" />
                    )}
                  </button>
                </div>

                <button
                  className={`btn btn-phone ${calling ? 'btn-phone-active' : ''}`}
                  onClick={LeaveCall}
                >
                  <IoCall className="i-phone-hangup bg-red-500 text-white rounded-full p-[12px] w-[45px] h-[45px]" />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

    </>
  );
};

export default Basics;
