import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import AgoraRTC, { AgoraRTCProvider } from "agora-rtc-react";

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);

// const functions = require('firebase-functions');
// const admin = require('firebase-admin');
// admin.initializeApp();


// Create a portal root for rendering modals or overlays
const portalRoot = document.createElement('div');
portalRoot.id = 'portal-root';
document.body.appendChild(portalRoot);

const client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });

client.on('user-published', (user, mediaType) => {
  // Make sure the user object is valid before subscribing
  if (user && mediaType) {
      client.subscribe(user, mediaType).then(() => {
          console.log('<<=========================== Subscribed to user');
      }).catch((error) => {
          console.log('<<=========================== Error subscribing to user:', error);
      });
  }
});

root.render(
  <React.StrictMode>
    <AgoraRTCProvider client={client}>
      <App />
    </AgoraRTCProvider>
  </React.StrictMode>
);

// Performance measurement
reportWebVitals();
