import axios from 'axios';
import React, { useEffect, useState } from 'react';
import OneSignal from 'react-onesignal';

const Onesignal = ({ message, receiverId, channel, img, type, id }) => {

    // Local Host Mate
    // app_id: '66365c40-3642-455f-b26d-99b492aee49a',
    // Authorization: 'os_v2_app_my3fyqbwijcv7mtntg2jflxetj6lb2oyweeuxb5avemvhuxa6saia54lgqkta6lgwivqk6uglo32cdga4wtdxc24uotrdzotrcbe4rq',

    // Live Mate
    // app_id: '94b2b6c5-fabb-4454-a2b7-75cf75b84789',
    // Authorization: 'os_v2_app_sszlnrp2xncfjivxoxhxlochrelsh3ri352efxmhy5xhzirj7rn3ky7ulmeesdx6kgnyz4z7xvrmw6ach4qrz6qkygi3xlljxlxn6wy',

    const sendPushNotification = async () => {
        try {
            await axios.post('https://onesignal.com/api/v1/notifications', { 
                app_id: '94b2b6c5-fabb-4454-a2b7-75cf75b84789',
                
                filters: [
                    { field: 'tag', key: 'user_id', value: receiverId }
                ],
                contents: {
                    en: message,
                },
                data: {
                    type: type,
                    channelName: channel,
                    userimg: img,
                    senderid: id,
                    receiverid: receiverId
                },
                content_available: false,
            }, {
                headers: {
                    Authorization: 'os_v2_app_sszlnrp2xncfjivxoxhxlochrelsh3ri352efxmhy5xhzirj7rn3ky7ulmeesdx6kgnyz4z7xvrmw6ach4qrz6qkygi3xlljxlxn6wy',
                },
            });
            console.log('Notification sent successfully');
        } catch (error) {
            console.error('Error sending notification:', error);
        }
    };


    useEffect(() => {
        if (message && receiverId) {
            sendPushNotification();
        }
    }, [message, receiverId])

    return null;
};

export default Onesignal;
