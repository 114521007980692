import {
  LocalUser,
  RemoteUser,
  useIsConnected,
  useJoin,
  useLocalMicrophoneTrack,
  useLocalCameraTrack,
  usePublish,
  useRemoteUsers,
} from "agora-rtc-react";
import React, { useContext, useEffect, useState } from "react";
import { FaVideo, FaVideoSlash } from "react-icons/fa";
import { FiMicOff, FiMic } from "react-icons/fi";
import { IoCall } from "react-icons/io5";
import { MyContext } from "../Context/MyProvider";
import { db } from "../Users_Chats/Firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";

const Basics = ({ Id, img, channelname }) => {

  const { setIsVideoCalling, setCallstatus, setAtendCall, imageBaseURL } = useContext(MyContext);

  const [calling, setCalling] = useState(false);
  const isConnected = useIsConnected();
  const [appId, setAppId] = useState("a1d71b610a904e26919704593fd53313");
  const [channel, setChannel] = useState(channelname);
  const [token, setToken] = useState(null);
  const [myProPic, setMyProPic] = useState();

  useJoin({ appid: appId, channel: channel, token: token }, calling);

  // local user
  const [micOn, setMic] = useState(true);
  const [cameraOn, setCamera] = useState(true);
  const { localMicrophoneTrack } = useLocalMicrophoneTrack(micOn);
  const { localCameraTrack } = useLocalCameraTrack(cameraOn);

  usePublish([localMicrophoneTrack, localCameraTrack]);

  // remote users
  const remoteUsers = useRemoteUsers();

  useEffect(() => {
    if (remoteUsers.length === 0 && calling) {
      setCalling(false);
      setIsVideoCalling(false);
      setCallstatus(false);
      setAtendCall(false);
    }
  }, [remoteUsers])

  useEffect(() => {
    if (!Id && !channelname) return;
    setCalling(true);
  }, [Id, channelname])

  const leaveCall = async () => {
    const Data = localStorage.getItem("Register_User");
    if (Id && Data) {

      const receiverId = JSON.parse(Data);

      const chatRoomId1 = `${Id}_${receiverId.id}`;
      const chatRoomId2 = `${receiverId.id}_${Id}`;

      const userRef1 = doc(db, "chat_rooms", chatRoomId1);
      const userRef2 = doc(db, "chat_rooms", chatRoomId2);

      // Reference to the 'isVoiceCall' subcollection within the document
      const isVcDocRef1 = doc(userRef1, "isVcAvailable", chatRoomId1);
      const isVcDocRef2 = doc(userRef2, "isVcAvailable", chatRoomId2);

      try {
        // Check if the document exists before updating it
        const docSnap1 = await getDoc(isVcDocRef1);
        if (docSnap1.exists()) {
          // Document exists, so update it
          await updateDoc(isVcDocRef1, { isVc: false });
          console.log(`Document in ${chatRoomId1} updated successfully.`);
        } else {
          console.log(`Document ${chatRoomId1} does not exist, skipping update.`);
        }

        const docSnap2 = await getDoc(isVcDocRef2);
        if (docSnap2.exists()) {
          // Document exists, so update it
          await updateDoc(isVcDocRef2, { isVc: false });
          console.log(`Document in ${chatRoomId2} updated successfully.`);
        } else {
          console.log(`Document ${chatRoomId2} does not exist, skipping update.`);
        }

      } catch (error) {
        console.error("Error updating collections: ", error);
      }
    }

    setCalling(false);
    setIsVideoCalling(false)
    setCallstatus(false);
    setAtendCall(false);
  }

  useEffect(() => {
    const Data = localStorage.getItem("Register_User");
    if (Data) {
      const Pic = JSON.parse(Data);
      if (Pic?.profile_pic) {
        setMyProPic(imageBaseURL + Pic?.profile_pic);
      } else {
        setMyProPic("https://www.agora.io/en/wp-content/uploads/2022/10/3d-spatial-audio-icon.svg");
      }
    }
  }, [])

  return (
    <>
      <div className="flex items-end Callimg justify-center room" style={{
        backgroundImage: `url(${img})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "500px",
        width: "100%",
      }}>
        <div className="" >
          {isConnected && (
            <div className="user-list">
              <div className="user You">
                <LocalUser
                  audioTrack={localMicrophoneTrack}
                  cameraOn={cameraOn}
                  micOn={micOn}
                  videoTrack={localCameraTrack}
                  cover={myProPic}
                >
                </LocalUser>
              </div>

              {remoteUsers.length > 0 ? remoteUsers.map((user) => (
                <div className="user remote" key={user.uid}>
                  <RemoteUser
                    cover={img}
                    user={user}
                    style={{ transform: 'scaleX(1)' }} // This will ensure the remote user's video is not flipped
                  />
                </div>
              )) : (
                <div className="w-[100%] h-[100%] flex justify-center z-[999] relative">
                  <h4 className="text-white">Ringing...</h4>
                </div>
              )}
            </div>
          )}

          {isConnected && (
            <div className="flex justify-center z-[999] relative">
              <div className="left-control">
                <button className="btn" onClick={() => setMic((a) => !a)}>
                  {micOn ? (
                    < FiMic className="bg-slate-600 text-white rounded-full p-[10px] w-[40px] h-[40px]" />
                  ) : (
                    <FiMicOff className="bg-slate-600 text-white rounded-full p-[10px] w-[40px] h-[40px]" />
                  )}
                </button>
                <button className="btn" onClick={() => setCamera((a) => !a)}>
                  {cameraOn ? (
                    <FaVideo className="bg-blue-600 text-white rounded-full p-[10px] w-[40px] h-[40px]" />
                  ) : (
                    <FaVideoSlash className="bg-blue-600 text-white rounded-full p-[10px] w-[40px] h-[40px]" />
                  )}
                </button>
                <button
                  className={` btn btn-phone ${calling ? "btn-phone-active" : ""}`}
                  onClick={leaveCall}
                >
                  <IoCall className="bg-red-500 text-white w-[40px] h-[40px] p-[10px] rounded-full" />
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Basics;
