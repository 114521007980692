import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getDatabase } from 'firebase/database';
import { getMessaging, getToken } from 'firebase/messaging';

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBbLUqwUcgskd_wfabUxb98-4Y54qC0aK4",
  authDomain: "redbus-3ec46.firebaseapp.com",
  projectId: "redbus-3ec46",
  storageBucket: "redbus-3ec46.firebasestorage.app",
  messagingSenderId: "319710447950",
  appId: "1:319710447950:web:970fea3fe3327a73a2f61f",
  measurementId: "G-4GCD8N81Z4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase services
const db = getFirestore(app);
const database = getDatabase(app);
const messaging = getMessaging(app);

// Function to request permission and retrieve the token
export const RecvestToken = async () => {
  try {
    // Request notification permission
    const permission = await Notification.requestPermission();
    // If permission is granted, fetch the token
    if (permission === "granted") {
      const token = await getToken(messaging, {
        vapidKey: "BMp4LjJ-zVFYs26Xu0TSTwKgTsUkIaML_JOnsiDLfhTyDKxC_QT-K2kuO46D_20vjnAcu2zONGGKuAdYgizw3NI"
      });
      if (token) {
        localStorage.setItem("Token", token); // Store token in localStorage
        return token;
      } else {
        console.error("Failed to retrieve Firebase token.");
        return null;
      }
    } else {
      console.warn("Notification permission denied.");
      return null;
    }
  } catch (error) {
    console.error("Error fetching Firebase token:", error);
    return null;
  }
};

// Export initialized Firebase services
export { db, database, messaging };
